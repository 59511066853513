<template>
  <div class="app-container">
    <CrudTable entity="CustomerAddress" :columns="columns" :generate-summary="generateSummary" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: ['companyName', 'firstName', 'lastName']
    };
  },
  methods: {
    generateSummary({ companyName }) {
      return `${companyName}`;
    }
  }
};
</script>

<style scoped></style>
